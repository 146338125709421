import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Helmet} from "react-helmet";
import "../assets/css/style.css";
import $ from "jquery";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const KeywordPage = () => {

  const data = useStaticQuery(graphql`
        query KeywordPage {
            wpTitle {
                titleMeta {
                    title
                    favicon {
                    sourceUrl
                    }
                }
            }
            wpKeystuffing {
                keyStuffing {
                  keywords
                  fieldGroupName
                  active
                }
            }
        }
    `)

const title = data.wpTitle.titleMeta.title;
const keyStuffing = data.wpKeystuffing.keyStuffing.keywords;

// This will run one time after the component mounts
useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
        window.location.href = "/";
      // do something else
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <main style={pageStyles}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <p style={{ color: '#fff' }}>{keyStuffing}</p>
    </main>
  )
}

export default KeywordPage
